<template>
  <div class="page-content">
    <div class="page-content-header">
      <div class="header-box">
        <h1>{{ $t('main_account_1') }}</h1>
        <el-button type="primary" @click="toReg" size="big">{{
          $t('main_account_2')
        }}</el-button>
      </div>
    </div>
    <div class="page-content-item">
      <div class="wth600">
        <img class="wth200" src="@/assets/img/index/digitalEconomy.png" />
        <h2>{{ $t('main_account_3') }}</h2>
        <div>
          {{ $t('main_account_4') }}
        </div>
      </div>
    </div>
    <div class="page-content-item">
      <div class="pdl300">
        <img class="wth200" src="@/assets/img/index/cheaperFasterSecure.png" />
        <h2>{{ $t('main_account_5') }}</h2>
        <div>{{ $t('main_account_6') }}</div>
      </div>
    </div>
    <div class="page-content-item">
      <div class="wth600">
        <img class="wth200" src="@/assets/img/index/virtualAccounts.png" />
        <h2>{{ $t('main_account_7') }}</h2>
        <div>{{ $t('main_account_8') }}</div>
      </div>
    </div>
    <div class="page-content-item">
      <div class="pdl300">
        <img class="wth200" src="@/assets/img/index/directTransactions.png" />
        <h2>{{ $t('main_account_9') }}</h2>
        <div>{{ $t('main_account_10') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'payment',
  methods: {
    toReg() {
      this.$router.push({ name: 'Email' })
    },
  },
}
</script>

<style scoped lang="scss">
.page-content {
  display: flex;
  flex-direction: column;

  .page-content-header {
    background: url(../../assets/img/index/banner.png) no-repeat center;
    height: 480px;

    .header-box {
      width: 960px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
    }

    h1 {
      color: #ffffff;
      width: 100%;
    }
  }

  .page-content-item {
    width: 960px;
    margin: 0 auto;
    padding: 70px 0;

    .wth600 {
      width: 600px;
    }

    .pdl300 {
      padding-left: 330px;
    }

    .wth200 {
      width: 200px;
    }
  }
}
</style>
